import React, { Component } from 'react';
import { ReactComponent as Download } from '../../img/svg/download.svg'
import { ReactComponent as ArrowDown } from '../../img/svg/arrow-down.svg'
import HRGif from '../../img/png/hrblock.gif'
import '../../css/mainBanner.css';
import Pdf from '../../pdf/H&R-Block-Outlook-on-American-Life-2023.pdf'

class MainBanner extends Component {

	handleClick = () => {
		let pageHeight = window.innerHeight;
		window.scrollBy({
			top: pageHeight,
			behavior: "smooth",
		});
	}
	render() {
		const mainBannerContent = this.props.mainBannerContentObj.componentContent[0];

		return (
			<div className="main-banner" id='main-banner'>
				<div className="banner-container">
					<div className="banner-text">
						<h3>{mainBannerContent.mainBannerText}</h3>
						<h1>{mainBannerContent.mainBannerTitle}</h1>
						<p>{mainBannerContent.mainBannerSubText}</p>
						<div className='btn-cta'>
							<a href={Pdf} target="_blank" rel="noopener noreferrer">
								{mainBannerContent.mainBannerCTABtnText}
								<Download />
							</a>

						</div>
					</div>
					<div className="banner-imgs">
						<img src={HRGif} alt='HR Block Gif HERO' />
					</div>
				</div>
				<div className='arrow-down' onClick={() => this.handleClick()}>
					<ArrowDown />
				</div>
			</div>
		)
	}
};

export default MainBanner;