import React, { Component } from 'react';
import Slider from "react-slick";
import {isMobile} from 'react-device-detect';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../css/movedTo.css';
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";
const customAnimation = keyframes`
from {
    opacity: 0;
    transform: translate(0, 100px);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;
const Item = ({ img, label}) =>      
    <div className="item">
        <div className="item-img">
            <img src={img} alt="" />
        </div>
        <div className="item-label">
            <p>
                {label}
            </p>
        </div>
	</div>


class MovedTo extends Component {
	
	
	render() {
		const MovedToContent = this.props.MovedToContentObj.componentContent[0]
		const BoomersMovedToContent = MovedToContent.boomer[0]
		const MillenialsMovedToContent = MovedToContent.millenial[0]
		var settings = {
			dots: false,
			infinite: false,
			speed: 500,
			
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false
		};
		if (isMobile) {
            return (
				<div className='moved-to'>
					<div className="moved-to-millenials">
						<div className="container">
							<Reveal triggerOnce  keyframes={customAnimation}>
								<div className='moved-to-title'>
									<h2>{MillenialsMovedToContent.title}</h2>
								</div>
								<div className="slider-container">
									<Slider {...settings}>
										{MillenialsMovedToContent.locations.map((item, index) => <Item key={index} {...item}/>)}
									</Slider>
								</div>
							</Reveal>
						</div>
					</div>
					<div className="moved-to-boomers">
						<div className="container">
							<Reveal triggerOnce  keyframes={customAnimation}>
								<div className='moved-to-title'>
									<h2>{BoomersMovedToContent.title}</h2>
								</div>
								<div className="slider-container">
									<Slider {...settings}>
										{BoomersMovedToContent.locations.map((item, index) => <Item key={index} {...item}/>)}
									</Slider>
								</div>
							</Reveal>
						</div>
					</div>
				</div>
            )
        }
		return (
			<div className='moved-to'>
				<div className="moved-to-millenials">
					<div className="container">
						<Reveal triggerOnce  keyframes={customAnimation}>
							<div className='moved-to-title'>
								<h2>{MillenialsMovedToContent.title}</h2>
							</div>
							<div className="slider-container">
								{MillenialsMovedToContent.locations.map((item, index) => <Item key={index} {...item}/>)}
							</div>
						</Reveal>
					</div>
				</div>
				<div className="moved-to-boomers">
					<div className="container">
						<Reveal triggerOnce  keyframes={customAnimation}>
							<div className='moved-to-title'>
								<h2>{BoomersMovedToContent.title}</h2>
							</div>
							<div className="slider-container">
								{BoomersMovedToContent.locations.map((item, index) => <Item key={index} {...item}/>)}
							</div>
						</Reveal>
					</div>
				</div>
			</div>
		)
	};
};
export default MovedTo;