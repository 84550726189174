import React from 'react';
import {Helmet} from "react-helmet";

const GTMComponent = () => {
    return (
        <>
        <Helmet>
            <script>
                {(function(w,d,s,l,i){
                w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(),
                event:'gtm.js',
                });
                var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl=l!=='dataLayer'?'&l='+l:'';
                j.async=true;
                j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-WNB8W77')}
            </script>
        </Helmet>
            <script dangerouslySetInnerHTML={{ __html: `
                <!-- Google Tag Manager (noscript) -->
                <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WNB8W77"
                height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
                <!-- End Google Tag Manager (noscript) -->
            ` }}
            async />
        </>
    )
}
export default GTMComponent;