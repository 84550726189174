import React, { Component } from 'react';
import topArrow from '../../img/png/Back-to-Top.png'
import '../../css/backToTopBtn.css'

class BackToTopBtn extends Component {
	handleClick = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		}); 
    }
	render() {
		return (
			<div className='BackToTopBtn' onClick={() => this.handleClick() }>
				<img src={topArrow} alt='' srcSet=''/>
            </div>
		)
	};
};
export default BackToTopBtn;