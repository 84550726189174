import React, { Component } from 'react';
import tt from '../../img/png/tt.png';
import ig from '../../img/png/ig.png';
import fb from '../../img/png/fb.png';
import yt from '../../img/png/yt.png';
import lk from '../../img/png/lkd.png';


import '../../css/footer.css';

class Footer extends Component {
	render() {
        const FooterContent = this.props.FooterContentObj.componentContent[0];
        console.log('FooterContent',FooterContent)
		return (
			<footer className="footer">
				<div className="container">
					<div className='footer-text'>
                        <p>
                            {FooterContent.text}
                        </p>
                    </div>
                    <div className='footer-socials'>
                        <div className="social">
							<a target="_blank" rel="noopener noreferrer" href={FooterContent.ttLink}>
								<img src={tt} alt="" srcset="" />
							</a>
						</div>
						<div className="social">
							<a target="_blank" rel="noopener noreferrer" href={FooterContent.fbLink}>
								<img src={fb} alt="" srcset="" />
							</a>
						</div>
						<div className="social">
							<a target="_blank" rel="noopener noreferrer" href={FooterContent.igLink}>
								<img src={ig} alt="" srcset="" />
							</a>
						</div>
						<div className="social">
							<a target="_blank" rel="noopener noreferrer" href={FooterContent.ytLink}>
								<img src={yt} alt="" srcset="" />
							</a>
						</div>
						<div className="social">
							<a target="_blank" rel="noopener noreferrer" href={FooterContent.lkdnLink}>
								<img src={lk} alt="" srcset="" />
							</a>
						</div>
                    </div>
				</div>
			</footer>
		)
	};
};
export default Footer;