import React, { Component } from 'react';

import '../../css/infoText.css';
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";
const customAnimation = keyframes`
from {
    opacity: 0;
    transform: translate(0, 100px);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;

class InfoText extends Component {
	render() {
		const InfoTextContent = this.props.InfoTextContentObj;
		if (InfoTextContent){
			return (
				<div className='info-text' >
					<div className="container-fluid">
					<Reveal triggerOnce  keyframes={customAnimation}>
						<div className="content">
							<div className="info-text-title">
							{InfoTextContent.id === 1 && 
								(
									<div className='sublime-millenial'></div>	
								)
							}
							{InfoTextContent.id === 2 && 
								(
									<div className='sublime-boomer'></div>	
								)
							}
							<h2>
								{InfoTextContent.title}
							</h2>
							</div>
							{ InfoTextContent.subtitle === '' ? null : (
								<p className={'text_bold'}>
									{InfoTextContent.subtitle}
								</p>
							)}
							{InfoTextContent.texts.map(({text}, index) => 
								<p className={'text_normal'} key={index}>
									{text}
								</p>
							)}
						</div>
					</Reveal>
					</div>
				</div>
			)
		}
		
	};
};
export default InfoText;