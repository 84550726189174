import React, { Component } from 'react';
import '../../css/depositions.css';

import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";
const customAnimation = keyframes`
from {
    opacity: 0;
    transform: translate(0, 100px);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;
class Depositions extends Component {
	render() {
        const DepositionsContent = this.props.DepositionsContentObj.componentContent[0];
        console.log('DepositionsContent', DepositionsContent)
		return (
            <div className='depositions'>
                <div className="container-fluid">
                    <div className="content">
                        <div className='deposition millennials'>
                            <Reveal triggerOnce  keyframes={customAnimation}>
                                <h2>
                                    "Interest rates and inflation rates have caused my minuscule raise to feel like a decrease in income."
                                </h2>
                                <div className="depo-data">
                                    <div className="data-percent">
                                        42%
                                    </div>
                                    <div className="data-text">
                                        of <span>Millennials</span> say they are better off this year than last.
                                    </div>
                                </div>
                                <div className="depo-data">
                                    <div className="data-percent">
                                    58%
                                    </div>
                                    <div className="data-text">
                                    say their income has increased over the last few years —more than any other generation.
                                    </div>
                                </div>
                                <div className="depo-data">
                                    <div className="data-percent">
                                    53%
                                    </div>
                                    <div className="data-text">
                                    Yet only half are satisfied with the pace of their gains.
                                    </div>
                                </div>
                            </Reveal>
                        </div>
                        <div className='deposition boomers'>
                            <Reveal triggerOnce  keyframes={customAnimation}>
                                <h2>
                                    “My income is not keeping up with inflation.”
                                </h2>
                                <div className="depo-data">
                                    <div className="data-percent">
                                        65%
                                    </div>
                                    <div className="data-text">
                                        of <span>Boomers</span> are dissatisfied with their income growth.
                                    </div>
                                </div>
                                <div className="depo-data">
                                    <div className="data-percent">
                                    42%
                                    </div>
                                    <div className="data-text">
                                    say they are worse off this year compared to last. 
                                    </div>
                                </div>
                                <div className="depo-data">
                                    <div className="data-percent">
                                    38%
                                    </div>
                                    <div className="data-text">
                                    believe their financial situation will remain the same. 
                                    </div>
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </div>
            </div>
		)
	};
};
export default Depositions;