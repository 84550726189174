import React, { Component } from 'react';
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";

import '../../css/personasAge.css';

const customAnimation = keyframes`
from {
    opacity: 0;
    transform: translate(0, 100px);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;
class PersonasAge extends Component {
	render() {
        const PersonasAgeContent = this.props.PersonasAgeContentObj.componentContent;
        const MillenialContent = PersonasAgeContent[0].millenial[0]
        const BoomerContent = PersonasAgeContent[0].boomer[0]
		return (
			<div className='personas-age' id='personas-age'>
                <div className='container-fluid'>
                    <div className='persona millenial'>
                        <Reveal triggerOnce  keyframes={customAnimation}>
                            <div className='persona-content'>
                                <h3>
                                    {MillenialContent.label}
                                </h3>
                                <p>
                                    {MillenialContent.ageText}
                                </p>
                            </div>
                            <div className='persona-img'>
                                <img src={MillenialContent.img} alt={MillenialContent.label} />
                            </div>
                        </Reveal>
                    </div>
                
                    <div className="squareAge"></div>
                    <div className='persona boomer'>
                        <Reveal triggerOnce  keyframes={customAnimation}>
                            <div className='persona-content'>
                                <h3>
                                    {BoomerContent.label}
                                </h3>
                                <p>
                                    {BoomerContent.ageText}
                                </p>
                            </div>
                            <div className='persona-img'>
                                <img src= {BoomerContent.img} alt={BoomerContent.label} />
                            </div>
                        </Reveal>
                    </div>
                
                </div>
            </div>
		)
	};
};
export default PersonasAge;