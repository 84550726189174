import React, { Component } from 'react';

import '../../css/infoText.css';
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";
const customAnimation = keyframes`
from {
    opacity: 0;
    transform: translate(0, 100px);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;

class InfoTextHighlight extends Component {
	render() {
		const InfoHighlightContent = this.props.InfoHighlightContentObj.componentContent[0];
		return (
			<div className="info-text highlight">
				<div className="container-fluid">
					<div className="content">
						<Reveal triggerOnce  keyframes={customAnimation}>
							<h2>
								{InfoHighlightContent.title}
							</h2>
							<p>
								{InfoHighlightContent.text}
							</p>
						</Reveal>
					</div>
				</div>
			</div>
		)
	};
};
export default InfoTextHighlight;