import React, { Component } from 'react';

import '../../css/marriedGraph.css';
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";
const customAnimation = keyframes`
from {
    opacity: 0;
    transform: translate(0, 100px);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;


class MarriedGraph extends Component {
	render() {
		const MarriedGraphContent = this.props.MarriedGraphContentObj.componentContent[0];
		return (
			<div className="married-graph" id='married-graph'>
				<div className="container">
					<div className="content">
						<Reveal triggerOnce keyframes={customAnimation}>
							<div className='infos'>
								<h2>
									Never— <br />or No Longer<br /> —Married
								</h2>
								<p>
									{MarriedGraphContent.text}
								</p>
								<div className='legend'>
									<div className='item'>
										<div className='item-label'>
											{MarriedGraphContent.legend[0].data1}
										</div>
										<div className='item-color'>
											<div className='thumb thumb-1'>
											</div>
										</div>
									</div>
									<div className='item'>
										<div className='item-label'>
											{MarriedGraphContent.legend[0].data2}
										</div>
										<div className='item-color'>
											<div className='thumb thumb-2'>
											</div>
										</div>
									</div>
									<div className='item'>
										<div className='item-label'>
											{MarriedGraphContent.legend[0].data3}
										</div>
										<div className='item-color'>
											<div className='thumb thumb-3'>
											</div>
										</div>
									</div>

								</div>
							</div>
							<div className='graphs'>
								<div className='millennials'>
									<div className='graph'>
										<div className='graph-title'>
											{MarriedGraphContent.millenial[0].age}
										</div>
										<div className='graph-container'>
											<div className='graph-column first-year'>
												<div className='graph-header'>
													{MarriedGraphContent.millenial[0].firstYear[0].label}
												</div>
												<div className='graph-data data-1'>
													{MarriedGraphContent.millenial[0].firstYear[0].data1}
												</div>
												<div className='graph-data-connexion-1'></div>
												<div className='graph-data data-2'>
													{MarriedGraphContent.millenial[0].firstYear[0].data2}
												</div>
												<div className='graph-data-connexion-2'></div>
												<div className='graph-data data-3'>
													{MarriedGraphContent.millenial[0].firstYear[0].data3}
												</div>
												<div className='graph-data-connexion-3'></div>
											</div>
											<div className='graph-column second-year'>
												<div className="drop">
													<div className='drop-img'>
														<img src={MarriedGraphContent.boomer[0].dropArrow} alt="DropArrow" srcSet="" />
													</div>
													<div className="drop-text">
														{MarriedGraphContent.millenial[0].drop}<br /> drop
													</div>
												</div>
												<div className='graph-header'>
													{MarriedGraphContent.millenial[0].secondYear[0].label}
												</div>
												<div className='graph-data data-1'>
													{MarriedGraphContent.millenial[0].secondYear[0].data1}
												</div>
												<div className='graph-data data-2'>
													{MarriedGraphContent.millenial[0].secondYear[0].data2}
												</div>
												<div className='graph-data data-3'>
													{MarriedGraphContent.millenial[0].secondYear[0].data3}
												</div>
											</div>
										</div>
									</div>
									<div className='picture'>
										<img src={MarriedGraphContent.millenial[0].image} alt="" srcSet="" />
									</div>
								</div>
								<div className='boomers'>
									<div className='graph'>
										<div className='graph-title'>
											{MarriedGraphContent.boomer[0].age}
										</div>
										<div className='graph-container'>
											<div className='graph-column first-year'>
												<div className='graph-header'>
													{MarriedGraphContent.boomer[0].firstYear[0].label}
												</div>
												<div className='graph-data data-1'>
													{MarriedGraphContent.boomer[0].firstYear[0].data1}
												</div>
												<div className='graph-data-connexion-1'></div>
												<div className='graph-data data-2'>
													{MarriedGraphContent.boomer[0].firstYear[0].data2}
												</div>
												<div className='graph-data-connexion-2'></div>
												<div className='graph-data data-3'>
													{MarriedGraphContent.boomer[0].firstYear[0].data3}
												</div>
												<div className='graph-data-connexion-3'></div>
											</div>
											<div className='graph-column second-year'>
												<div className="drop">
													<div className='drop-img'>
														<img src={MarriedGraphContent.boomer[0].dropArrow} alt="DropArrow" srcSet="" />
													</div>
													<div className="drop-text">
														{MarriedGraphContent.boomer[0].drop}<br /> drop
													</div>
												</div>
												<div className='graph-header'>
													{MarriedGraphContent.boomer[0].secondYear[0].label}
												</div>
												<div className='graph-data data-1'>
													{MarriedGraphContent.boomer[0].secondYear[0].data1}
												</div>
												<div className='graph-data data-2'>
													{MarriedGraphContent.boomer[0].secondYear[0].data2}
												</div>
												<div className='graph-data data-3'>
													{MarriedGraphContent.boomer[0].secondYear[0].data3}
												</div>
											</div>
										</div>
									</div>
									<div className='picture'>
										<img src={MarriedGraphContent.boomer[0].image} alt="" srcSet="" />
									</div>
								</div>
							</div>
						</Reveal>
					</div>
				</div>
			</div>
		)
	};
};
export default MarriedGraph;