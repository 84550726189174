import React, { Component } from 'react';
import { ReactComponent as BlueLine } from '../../img/svg/blue-line.svg'
import { ReactComponent as YellowLine } from '../../img/svg/yellow-line.svg'
import { ReactComponent as YellowArrow } from '../../img/svg/yellow-arrow.svg'
import { ReactComponent as BlueArrow } from '../../img/svg/blue-arrow.svg'


import '../../css/living.css';
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";
const customAnimation = keyframes`
from {
    opacity: 0;
    transform: translate(0, 100px);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;
class Living extends Component {
    render() {
        const LivingContent = this.props.LivingContentObj.componentContent[0];
        const LivingTexts = LivingContent.texts
        console.log('LivingContent', LivingContent.graph[0].title)
        return (
            <div className="living">
                <div className="container">
                    <Reveal triggerOnce keyframes={customAnimation}>
                        <div className="living-title">
                            <h2>{LivingContent.title}</h2>
                        </div>
                    </Reveal>
                    <div className="content">
                        <Reveal triggerOnce keyframes={customAnimation}>
                            <div className="living-text">
                                <p>
                                    <span>
                                        {LivingContent.subtitle}
                                    </span>
                                </p>
                                {
                                    LivingTexts.map(({ text }, index) =>
                                        <p key={index}>
                                            {text}
                                        </p>
                                    )
                                }
                                <p className='consideration'>
                                    <span >
                                        {LivingContent.consideration}
                                    </span>
                                </p>
                            </div>
                        </Reveal>
                        <div className="living-graph">
                            <div className="living-graph-title">
                                {LivingContent.graph[0].title}
                            </div>
                            <div className="living-graph-years">
                                <div className='year1'>
                                    2020
                                </div>
                                <div className='year2'>
                                    2021
                                </div>
                            </div>
                            <div className='blue-line'>
                                <BlueLine />
                            </div>
                            <div className='living-porcentage porcentage1'>
                                <BlueArrow /> 16.7%
                            </div>
                            <div className='info1'>
                                3.8%
                            </div>
                            <div className='info2'>
                                4.1%
                            </div>
                            <div className='yellow-line'>
                                <YellowLine />
                            </div>
                            <div className='living-porcentage porcentage2'>
                                <YellowArrow /> 7.9%
                            </div>
                            <div className='info3'>
                                1.2%
                            </div>
                            <div className='info4'>
                                1.4%
                            </div>
                            <div className="legenda">
                                <div className='legenda-item'>
                                    <div className="item-label">
                                        Millennials
                                    </div>
                                    <div className='item-thumb thumb-m' >
                                    </div>
                                </div>
                                <div className='legenda-item'>
                                    <div className="item-label">
                                        Boomers
                                    </div>
                                    <div className='item-thumb thumb-b' >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default Living;