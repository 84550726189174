import React, { useEffect } from 'react';
import logoImg from '../../../img/png/green.png'

import { ReactComponent as Share } from '../../../img/svg/share.svg';

import '../../../css/desktopHeader.css';


const DesktopHeader = () => {

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector('.desktop-header');
    const scrollTop = window.scrollY;
    scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };

  return (
    <header className="desktop-header">
      <div className="header_inner">
        <div className="header_nav">
          <div className="logo">
            <img src={logoImg} alt='H&R Block Website' />
          </div>
          <nav>
            <ul>
              <li>
                <a href='https://www.hrblock.com/'>H&R Block Website<Share /></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default DesktopHeader;
