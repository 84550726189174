import React, { Component } from 'react';
import '../../css/depositions.css';
import infoImgBoomer from '../../img/png/infoimgBoomerv2.png'
import infoImgMillenial from '../../img/png/infoimgMillennialv2.png';

import '../../css/infoImage.css';
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";
const customAnimation = keyframes`
from {
    opacity: 0;
    transform: translate(0, 100px);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;
class InfoImage extends Component {
	render() {
        const InfoImageContent = this.props.InfoImageContentObj
        const depositions = InfoImageContent.depositions
        var contentMillenial = "content-millenial "
        var contentBoomer = "content-boomer "
		return (
            <div className='info-image'>
                <div className="container">
                    <div className= {InfoImageContent &&  InfoImageContent.type === "millenial" ? contentMillenial + "content" : contentBoomer + "content"}>
                        <div className='info-image_img'>
                            {InfoImageContent  && InfoImageContent.type === 'millenial'? 
                                (<img src={infoImgMillenial} alt="" srcSet="" />)
                                :
                                (<img src={infoImgBoomer} alt="" srcSet="" />)
                            }
                            
                        </div>
                        <div className='info-image_text'>
                            {InfoImageContent &&  InfoImageContent.type === 'millenial'?
                                <div className='info-image_text_container'>
                                    <Reveal triggerOnce  keyframes={customAnimation}>
                                        <h3>
                                            {InfoImageContent && (InfoImageContent.title)}
                                        </h3>
                                        <p>
                                            {InfoImageContent && (InfoImageContent.subtitle)}
                                        </p>
                                        <h4>
                                            {InfoImageContent && (InfoImageContent.data[0].title)}
                                        </h4>
                                        <div className='info-image_data'>
                                            <div className='info-image_data_percentage'>
                                                {InfoImageContent && (InfoImageContent.data[0].percentage)}
                                            </div>
                                            <div className='info-image_data_info'>
                                                {InfoImageContent && (InfoImageContent.data[0].text)}
                                            </div>
                                        </div>
                                    </Reveal>
                                </div>
                            :
                                <div className='info-image_text_container info-image-boomer'>
                                    <Reveal triggerOnce  keyframes={customAnimation}>
                                        <div className='info-image-depositions'>
                                            <div className='info-image-deposition'>
                                                {
                                                    depositions.map(({deposition}, index) => 
                                                        <p key={index}>
                                                            {deposition}
                                                        </p>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <h4>
                                            {InfoImageContent && (InfoImageContent.data[0].title)}
                                        </h4>
                                        <div className='info-image_data'>
                                            <div className='info-image_data_percentage'>
                                                {InfoImageContent && (InfoImageContent.data[0].percentage)}
                                            </div>
                                            <div className='info-image_data_info'>
                                                {InfoImageContent && (InfoImageContent.data[0].text)}
                                            </div>
                                        </div>
                                    </Reveal>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
		)
	};
};
export default InfoImage;