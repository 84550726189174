import React, { Component } from 'react';
import { ReactComponent as Download } from '../../img/svg/download.svg'
import Pdf from '../../pdf/H&R-Block-Outlook-on-American-Life-2023.pdf'
import '../../css/infoText.css';

import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";
const customAnimation = keyframes`
from {
    opacity: 0;
    transform: translate(0, 100px);
}

to {
    opacity: 1;
    transform: translate(0, 0);
}
`;
class DownloadSection extends Component {
    render() {
        const DownloadSectionContent = this.props.DownloadSectionContentObj.componentContent[0];
        return (
            <div className='info-text highlight download'>
                <div className="container-fluid">
                    <Reveal triggerOnce keyframes={customAnimation}>
                        <div className="content">
                            <p>
                                {DownloadSectionContent.text}
                            </p>
                            <div className='btn-cta'>
                                <a href={Pdf} target="_blank" rel="noopener noreferrer">
                                    {DownloadSectionContent.CTABtnText}<Download />
                                </a>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>
        )
    };
};
export default DownloadSection;