import React, { useState } from "react";

//Components
import GTMComponent from "../../components/GTM/gtm";
import BackToTopBtn from "../../components/BackToTopBtn/backToTop";
import DesktopHeader from '../../components/Header/DesktopHeader/desktopHeader';
import MobileHeader from '../../components/Header/MobileHeader/mobileHeader';
import MainBanner from '../../components/MainBanner/mainBanner';
import InfoText from "../../components/InfoText/infoText.jsx";
import InfotextHighligt from '../../components/InfoTextHighlight/infoTextHighlight'  
import Footer from '../../components/Footer/footer.jsx';
import PersonasAge from "../../components/PersonasAge/personasAge.jsx";
import DownloadSection from "../../components/DownloadSection/downloadSection";
import MarriedGraph from "../../components/MarriedGraph/marriedGraph";
import Depositions from "../../components/Depositions/Depositions";
import InfoImage from "../../components/InfoImage/infoImage";
import { shelf } from "../../components/content";
import MovedTo from "../../components/MovedTo/movedTo";
import Living from "../../components/Living/living";

//Components Content
const headerContent = shelf.content[0]
const mainBannerContent = shelf.content[1]
const InfoHighlightContent = shelf.content[2]
const PersonasAgeContent = shelf.content[3]
const FirstDownloadSectionContent = shelf.content[4]
const MarriedGraphContent = shelf.content[5]
const FirstInfoTextContent = shelf.content[6]
const DepositionsContent = shelf.content[7]
const SecondDownloadSectionContent = shelf.content[8]
const SecondInfoTextContent = shelf.content[9]
const FirstInfoImageContent = shelf.content[10].componentContent[0]
const ThirdInfoTextContent = shelf.content[11]
const SecondInfoImageContent = shelf.content[12].componentContent[0]
const ThirdDownloadSectionContent = shelf.content[13]
const LivingContent = shelf.content[14]
const MovedToContent = shelf.content[15]
const FourthDownloadSectionContent = shelf.content[16]
const FooterContent = shelf.content[17]


export default function Home() {

    const [isOpen, setIsOpen] = useState(false);

    window.addEventListener("scroll", function() {
        var elementTarget = document.getElementById("personas-age");
        if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
            setIsOpen(true)
        }
        if (window.scrollY < (elementTarget.offsetTop + elementTarget.offsetHeight)) {
            setIsOpen(false)
        }
    });
    return(
        <React.Fragment>
            < GTMComponent />
            {   isOpen? <BackToTopBtn/> : null  }
            < DesktopHeader headerContentObj = {headerContent}/>
            < MobileHeader  headerContentObj = {headerContent}/>
            < MainBanner mainBannerContentObj = {mainBannerContent}/>
            < InfotextHighligt InfoHighlightContentObj = {InfoHighlightContent} />
            < PersonasAge PersonasAgeContentObj = {PersonasAgeContent} />
            < DownloadSection DownloadSectionContentObj = {FirstDownloadSectionContent} />
            < MarriedGraph MarriedGraphContentObj = {MarriedGraphContent}/>
            < InfoText InfoTextContentObj = {FirstInfoTextContent} />
            < Depositions DepositionsContentObj = {DepositionsContent}/>
            < DownloadSection DownloadSectionContentObj = {SecondDownloadSectionContent} />
            < InfoText InfoTextContentObj = {SecondInfoTextContent} />
            < InfoImage InfoImageContentObj = {FirstInfoImageContent}/>
            < InfoText InfoTextContentObj = {ThirdInfoTextContent} />
            < InfoImage InfoImageContentObj = {SecondInfoImageContent}/>
            < DownloadSection DownloadSectionContentObj = {ThirdDownloadSectionContent} />
            < Living LivingContentObj = {LivingContent}/>
            < MovedTo MovedToContentObj = {MovedToContent}/>
            < DownloadSection DownloadSectionContentObj = {FourthDownloadSectionContent} />
            < Footer FooterContentObj = {FooterContent} />
        </React.Fragment> 
    );
}
