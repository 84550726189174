
import logoImg from '../img/png/green.png'
import MainBannerimg1 from '../img/png/mb1.png';
import MainBannerimg2 from '../img/png/mb2.png';
import MainBannerimg3 from '../img/png/mb3.png';
import MainBannerimg4 from '../img/png/mb4.png';
import MainBannerimg5 from '../img/png/mb5.png';
import MainBannerimg6 from '../img/png/mb6.png';
import MainBannerimg7 from '../img/png/mb7.png';
import MainBannerimg8 from '../img/png/mb8.png';
import MainBannerimg9 from '../img/png/mb9.png';
import Millenial from '../img/png/millenialv2.png';
import Boomer from '../img/png/boomerv2.png';
import graphMillenial from '../img/png/graphMillenialv2.png';
import graphBoomer from '../img/png/graphBoomerv2.png';
import grayArrow from '../img/png/gray-arrow.png'
import infoImgMillenial from '../img/png/infoimgMillennialv2.png';
import infoImgBoomer from '../img/png/infoimgBoomerv2.png';
import Florida from '../img/png/Florida.png'
import Texas from '../img/png/Texas.png'
import California from '../img/png/California.png'
import NorthCarolina from '../img/png/NorthCarolina.png'
import Virginia from '../img/png/Virginia.png'
import Arizona from '../img/png/Arizona.png'
import SouthCarolina from '../img/png/SouthCarolina.png'

const content = [
    {
        componentName: 'Header',
        componentContent: [
            {
                logo: logoImg,
                siteLabel: 'H&R Block Website',
                siteUrl: '#',
            }
        ],
    },
    {
        componentName: 'MainBanner',
        componentContent: [
            {
                mainBannerText: 'Outlook on American Life 2023',
                mainBannerTitle: 'Who Are Middle Americans Today?',
                mainBannerSubText: 'Twenty million tax returns tell us they’re not who they used to be.',
                mainBannerCTABtnText: 'Download report',
                mainBannerCTABtnFileLink: '#',
                mainBannerImgs: [
                    {
                        img: MainBannerimg1
                    },
                    {
                        img: MainBannerimg2
                    },
                    {
                        img: MainBannerimg3
                    },
                    {
                        img: MainBannerimg4
                    },
                    {
                        img: MainBannerimg5
                    },
                    {
                        img: MainBannerimg6
                    },
                    {
                        img: MainBannerimg7
                    },
                    {
                        img: MainBannerimg8
                    },
                    {
                        img: MainBannerimg9
                    }
                ]
            }
        ],
    },
    {
        componentName: 'InfoHighlight',
        componentContent: [
            {
                title: 'A New Middle Emerges — at Both Ends',
                text: 'Middle American tax filers, making $45,000 - $145,000, are likeliest to be either Millennials or Boomers.'
            }
        ],
    },
    {
        componentName: 'Millenials&Boomers',
        componentContent: [
            {
                millenial: [
                    {
                        label: 'Millennials',
                        ageText: 'around 32 years of age',
                        img: Millenial
                    }
                ],
                boomer: [
                    {
                        label: 'Boomers',
                        ageText: 'around 62 years of age',
                        img: Boomer
                    }
                ]
            }
        ],
    },
    {
        componentName: 'DownloadSection',
        id: 0,
        componentContent: [
            {
                text: 'By looking at almost a billion datapoints from this subset since 2000, we can see not only who Middle Americans are today, but also how these taxpayers have changed over time.',
                CTABtnText: 'Explore the full report',
                CTABtnFileLink: '#',
            }
        ],
    },
    {
        componentName: 'MarriageGraph',
        componentContent: [
            {
                title: 'Never — or No Longer — Married',
                text: 'Only 43% of filers ages 25-44—30% fewer than a generation ago—filed jointly in 2023. While half of filers ages 55+ filed jointly, that’s 20% less than in 2000.',
                legend: [
                    {
                        data1: 'Single',
                        data2: 'Married Filing Jointly',
                        data3: 'Head of household'
                    }
                ],
                millenial: [
                    {
                        age: 'Filers Ages: 25-45',
                        firstYear: [
                            {
                                label: '2000',
                                data1: '73%',
                                data2: '18%',
                                data3: '8%'
                            }
                        ],
                        secondYear: [
                            {
                                label: '2022',
                                data1: '43%',
                                data2: '37%',
                                data3: '17%'
                            }
                        ],
                        dropArrow: grayArrow,
                        drop: '30%',
                        image: graphMillenial
                    }
                ],
                boomer: [
                    {
                        age: 'Filers Ages: 55+',
                        firstYear: [
                            {
                                label: '2000',
                                data1: '70%',
                                data2: '24%',
                                data3: '5%'
                            }
                        ],
                        secondYear: [
                            {
                                label: '2022',
                                data1: '50%',
                                data2: '43%',
                                data3: '5%'
                            }
                        ],
                        dropArrow: grayArrow,
                        drop: '20%',
                        image: graphBoomer
                    }
                ]
            }
        ],
    },
    {
        componentName: 'InfoText',
        id: 0,
        title: "The Middle’s Outlook on Income",
        subtitle: '',
        texts: [
            {
                text: 'To better understand the demographic trends, we surveyed 1,007 taxpayers aged 18 and above, with 40% of them being Millennials (ages 25-44) and 42% being Boomers (55+).'
            },
            {
                text: 'We found that for those who reported a household income of $45,000–$145,000, the majority earned under $80,000, which is consistent with the median US household income of $70,784.'
            }
        ],
    },
    {
        componentName: 'Depositions',
        componentContent: [
            {
                millenial: [
                    {
                        deposition: '“Interest rates and inflation rates have caused my minuscule raise to feel like a decrease in income.”',
                        data1: [
                            {
                                percentage: '42%',
                                text: 'of Millennials say they are better off this year than last.'
                            }
                        ],
                        data2: [
                            {
                                percentage: '58%',
                                text: 'say their income has increased over the last few years —more than any other generation.'
                            }
                        ],
                        data3: [
                            {
                                percentage: '53%',
                                text: 'Yet only half are satisfied with the pace of their gains.'
                            }
                        ]
                    }
                ],
                boomer: [
                    {
                        deposition: '“My income is not keeping up with inflation.”',
                        data1: [
                            {
                                percentage: '65%',
                                text: 'of Boomers are dissatisfied with their income growth.'
                            }
                        ],
                        data2: [
                            {
                                percentage: '42%',
                                text: 'say they are worse off this year compared to last. '
                            }
                        ],
                        data3: [
                            {
                                percentage: '38%',
                                text: 'believe their financial situation will remain the same. '
                            }
                        ],
                        data4: [
                            {
                                percentage: '28%',
                                text: 'believe it will get worse.'
                            }
                        ]
                    }
                ]
            }
        ],
    },
    {
        componentName: 'DownloadSection',
        id: 1,
        componentContent: [
            {
                text: 'Our assisted-return dataset, when combined with data from a general-population survey we commissioned, reveals a nuanced and surprisingly overlooked portrait of this demographic.',
                CTABtnText: 'Delve into the full report',
                CTABtnFileLink: '#',
            }
        ],
    },
    {
        componentName: 'InfoText',
        id: 1,
        title: "Millennials Work the Hardest of All Generations",
        subtitle: 'Due to concerns over financial stability, Millennials have become the hardest working of all generations.',
        texts: [
            {
                text: '44% of Millennials feel overwhelmed by financial burdens. 62% are extremely concerned about inflation. On average, Millennials work two jobs, whereas most generations average just one.'
            }
        ],
    },
    {
        componentName: 'InfoTextPlusImg',
        id: 0,
        componentContent: [
            {
                type: 'millenial',
                img: infoImgMillenial,
                title: "1 in 4 plans to start a business. 1 in 3 plans to work for an app-based company.",
                subtitle: 'Despite being the most likely to have children under 18, Millennials are the most likely of all generations to be renting their residence to make more money.',
                data: [
                    {
                        title: 'Still, they’re hopeful:',
                        percentage: '67%',
                        text: 'believes that next year their income will go up (more than any other generation in our sample).'
                    }
                ]
            }
        ],
    },
    {
        componentName: 'InfoText',
        id: 2,
        title: "Boomers Are Pushing Out Retirement",
        subtitle: '44% of Boomers in our sample are retired. Nearly as many — 38% — are working full time. A few (13%) work part-time; 10% say they have a side hustle.',
        texts: [
            {
                text: 'Like other generations, if given $500 today, most Boomers would use it to pay bills or buy groceries. Nearly 90% of Boomers know what’s in their bank account at any given time.'
            }
        ],
    },
    {
        componentName: 'InfoTextPlusImg',
        id: 1,
        componentContent: [
            {
                type: 'boomer',
                img: infoImgBoomer,
                depositions: [
                    {
                        deposition: '“Because I am 60 and do not have nearly enough saved for retirement”'
                    },
                    {
                        deposition: '“Because I am working extra hours in order to make more money”'
                    }
                ],
                data: [
                    {
                        title: 'Nearly,',
                        percentage: '90%',
                        text: 'know what`s in their bank account at any given time.'
                    }
                ]
            }
        ],
    },
    {
        componentName: 'DownloadSection',
        id: 3,
        componentContent: [
            {
                text: 'Although incomes took a hit in 2020, Millennials and Boomers recovered in 2022. In fact, the AGI (Adjusted Gross Income) reported for both Millennials and Boomers in 2023 exceeded what the historical trend lines predicted.',
                CTABtnText: 'Get the full report',
                CTABtnFileLink: '#',
            }
        ],
    },
    {
        componentName: 'Living',
        componentContent: [
            {
                title: 'No Longer Living in the Middle',
                subtitle: 'Today, middle-income Millennials and Boomers are most likely to be living on the coasts.',
                texts: [
                    {
                        text: 'In 2020, rolling lockdowns and persistent uncertainty dampened state migration rates across all age groups. But as travel resumed, migration rebounded. '
                    },
                    {
                        text: 'In 2021, Millennials were 16.7% more inclined to change states than they were prior to the pandemic; Baby Boomers were 7.9% more inclined.'
                    }
                ],
                consideration: 'Where did they wind up? Not in the heartland.',
                graph: [
                    {
                        title: 'Propensity to Change States by Age',
                        data: [
                            {
                                millenial: [
                                    {
                                        firstYear: [
                                            {
                                                label: '2020',
                                                data1: '3.8%'
                                            }
                                        ],
                                        secondYear: [
                                            {
                                                label: '2021',
                                                data1: '4.1%'
                                            }
                                        ],
                                        resume: '16.7%'
                                    }
                                ],
                                boomer: [
                                    {
                                        firstYear: [
                                            {
                                                label: '2020',
                                                data1: '1.2%'
                                            }
                                        ],
                                        secondYear: [
                                            {
                                                label: '2021',
                                                data1: '1.4%'
                                            }
                                        ],
                                        resume: '7.9%'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
    },
    {
        componentName: 'MovedTo',
        componentContent: [
            {
                millenial: [
                    {
                        title: 'Millenials moved to:',
                        locations: [
                            {
                                img: Texas,
                                label: 'Texas'
                            },
                            {
                                img: Florida,
                                label: 'Florida'
                            },
                            {
                                img: California,
                                label: 'California'
                            },
                            {
                                img: NorthCarolina,
                                label: 'North Carolina'
                            },
                            {
                                img: Virginia,
                                label: 'Virginia'
                            },
                        ]
                    }
                ],
                boomer: [
                    {
                        title: 'Boomers moved to:',
                        locations: [
                            {
                                img: Florida,
                                label: 'Florida'
                            },
                            {
                                img: Texas,
                                label: 'Texas'
                            },
                            {
                                img: Arizona,
                                label: 'Arizona'
                            },
                            {
                                img: NorthCarolina,
                                label: 'North Carolina'
                            },
                            {
                                img: SouthCarolina,
                                label: 'South Carolina'
                            },
                        ]
                    }
                ]
            }
        ],
    },
    {
        componentName: 'DownloadSection',
        id: 3,
        componentContent: [
            {
                text: 'What may be surprising is where Middle Americans wound up. The report indicates that many Middle Americans leave large cities and colder states for less congested areas and warmer climates.',
                CTABtnText: 'Dive into the full report',
                CTABtnFileLink: '#',
            }
        ],
    },
    {
        componentName: 'Footer',
        componentContent: [
            {
                text: 'Copyright © 2022-2023 HRB Digital LLC. All Rights Reserved. Bank products and services are offered by Pathward, N.A. All deposit accounts through Pathward are FDIC insured.',
                ttLink: 'https://www.twitter.com/hrblock',
                fbLink: 'https://www.facebook.com/hrblock',
                igLink: 'https://www.instagram.com/hrblock/',
                ytLink: 'https://www.youtube.com/hrblock',
                lkdnLink: 'https://www.linkedin.com/company/h&r-block',
            }
        ]
    }
]


const shelf = {
    content
};

export { shelf };
